.para{
    font-family: 'IBM Plex Sans Arabic', sans-serif;
    font-weight: 700;
    font-size: 55.56px;
    color: #3e1759;
    direction: rtl;
    margin-top: 100px;
    margin-right: 35px;
    margin-bottom: 25px;
}
.para2{
    font-family: 'IBM Plex Sans Arabic', sans-serif;
    font-weight: 100;
    font-size: 40px;
    color: #3e1759;
    direction: rtl;
    margin-right: 35px;
    margin-bottom: 166px;
    
    
}
.para3{
    font-family: 'IBM Plex Sans Arabic', sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #3e1759;
    direction: rtl;
}
.formm{
    height: 50px !important;
    border-radius: 15px !important;
    width: 100% !important;
    border: 1.5px #3e1759 solid !important;
}
.btt{
    background-color: #50007a !important;
    color: white !important;
    font-family: 'IBM Plex Sans Arabic', sans-serif;
    font-weight: 800;
    height: 45px;
    border-radius: 10px;
   
}
.rtl{
  direction: rtl;
}
.container3 {
    background: linear-gradient(to right, #420065, #50007a);
    padding: 20px;
    border-radius: 20px;
    max-width: 65%;
    margin: auto;
    color: white;
    margin-top: 13rem;
    margin-bottom: 200px;
  }
  
  .formTitle {
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'IBM Plex Sans Arabic';
  }
  
  .formGroup {
    margin-bottom: 20px;
  }
  
  .formControl {
    border: 1px solid white;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    width: 100%;
    direction: rtl;
    height:60px;
    padding-right: 10px;
  }
  
  .formControl::placeholder {
    color: #ddd;
  }
  
  .btnSubmit {
    background-color: white !important;
    color: black !important;
    font-family:'IBM Plex Sans Arabic';
    font-size: 25.32px;
    font-weight: 600;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
  }
  .location{
    font-family: 'IBM Plex Sans Arabic';
    font-weight: 700;
    font-size: 37.49px;
    color: #3e1759;
    text-align: right;
}
.location1{
    font-family: 'IBM Plex Sans Arabic';
    font-weight: 100;
    font-size: 15.49px;
    color: #917aa0;
    text-align: right;

}
.container2{
    margin-top: 13rem;
}

.errorBorder {
  border-color: red !important;
}

.errorMessage {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  text-align: right;
}

@media (max-width: 992) {
  .btt{
    font-size: 14px !important;
  }
}
@media (max-width: 767px) {
  .kn, .kn2{
    padding-right: 2rem !important;
  }
  .btt{
    font-size: 14px !important;
  }
  .paraa3{
    margin-bottom: 35px !important;
  }
  .container3{
    max-width: 100% !important;
  }
}
@media (max-width: 576.98px){
  .para2{
    font-size: 26px;
    margin-bottom: 70px;
  }
  .para{
    font-size: 45px;
    margin-top: 100px;
    margin-right: 35px;
    margin-bottom: 16px;
  }
  .container3{
    margin-top: 5rem;
  }
}
@media (max-width: 427px) {
  .btt{
    font-size: 11px !important;
    width: 25% !important;
  }
  .paraa3{
    margin-top: 35px !important;
  }
  
}