.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2e004c;
  padding: 10px 20px;
  height: 90px;
  position: relative;
  z-index: 1000;
  direction: rtl;
}
.searchInput {
  width: 200px; 
  padding: 5px; 
  font-size: 16px; 
  border-radius: 4px; 
  border: 1px solid #ccc;
  outline: none; 
  transition: width 0.4s ease-in-out; 
  margin-left: 10px; 
}

.searchInput:focus {
  width: 250px;
  border-color: #007bff; 
}

/* searchInput */
.searchInput {
  width: 200px; /* عرض الحقل */
  padding: 5px; /* المسافة الداخلية للحقل */
  font-size: 16px; /* حجم النص داخل الحقل */
  border-radius: 4px; /* زوايا الحقل */
  border: 1px solid #ccc; /* حدود الحقل */
  outline: none; /* إزالة التحديد الافتراضي عند التركيز */
  transition: width 0.4s ease-in-out; /* تأثير توسيع العرض عند التركيز */
  margin-left: 10px; /* مسافة من الأيقونات المجاورة */
}

.searchInput:focus {
  width: 250px; /* زيادة عرض الحقل عند التركيز */
  border-color: #007bff; /* تغيير لون الحدود عند التركيز */
}

/* resultsContainer */
/* .resultsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
  margin-top: 20px;
} */

/* card */
.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  width: 100%;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 10px;
}

.card h3 {
  font-size: 18px;
  margin: 10px 0;
}

.card p {
  font-size: 14px;
  color: #555;
  margin: 5px 0;
  width: 100%;
  word-break: break-all;
}



.logo img {
height: 70px;
}
.navLinks {
margin: 0;
width: 90%;
list-style: none;
display: flex;
justify-content: space-around;
}

.navLinks li {
font-size: 22px;
font-weight: bold;
}

.navLinks a ,.navLinks a i{
color: white;
text-decoration: none;
transition: 0.5s;
}
.navLinks li{
color: white;
text-decoration: none;
transition: 0.5s;
cursor: pointer;
}
.navLinks a:hover,.navLinks li:hover, .navLinks a:hover i, .active, .active i{
color: #6F1B9F !important;
}
.icons {
display: flex;
gap: 10px;
margin-left: 30px;
}

.icon {
font-size: 22px;
font-weight: bold;
color: white;
cursor: pointer;
transition: 0.5s;
}
.fixedNavbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}

.icon:hover{
color: #6F1B9F !important;
}
.border{
border: 5px solid white;
border-radius: 50%;
padding: 4px;
transition: 0.5s;
}
.border:hover{
border: 5px solid #6F1B9F !important;
} 
label #btn,
label #cancel{
color: #fff;
font-size: 30px;
float: right;
cursor: pointer;
padding-left: 18px;
display: none;
margin-top: 4px;
}
.icons label{
display: none;
}

.resultsModal {
  position: fixed;
  top: 30px;
  bottom: 30px;
  left: 12.5%;
  width: 75%;
  /* height: 100%; */
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.bgCover{
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
}
.academy{
  height: 150px !important;
}

.closeButton {
  align-self: flex-start;
  font-size: 50px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #333;
}

.resultsContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  width: 100%;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.userImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.userInfo h3 {
  margin: 10px 0;
}

.userInfo p {
  margin: 5px 0;
}

@media (max-width: 991.98px){
.icons label{
  display: block;
}
ul{
  position: absolute;
  width: 100% !important;
  flex-direction: column;
  background-color: #2e004c;
  top: -900px;
  left: 0;
  text-align: center;
  /* transition: all 0.5s; */
  /* z-index: 5; */
  border-radius: 0 0 40px 40px;
}
.mobile{
  top: 90px;
}
nav ul li{
  padding: 20px;
}
}
@media (max-width: 576.98px){
  .academy{
    height: 115px !important;
  }
  label #btn,
  label #cancel{
    font-size: 24px;
    padding-left: 5px;
    margin-top: 4px;
  }
  .icon{
    font-size: 16px;
  }
  .icons{
    margin-left: 0px;
  }
  .navLinks li{
    font-size: 18px;
    padding: 15px;
  }
  .searchInput{
    position: absolute;
    width: 85%;
    padding: 5px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    outline: none;
    transition: width 0.4s ease-in-out;
    margin-left: 0px;
    bottom: -38px;
    right: 7px;
    left: 7px;
    margin: auto;
  }
  .searchInput:focus {
    width: 95%;
  }
}