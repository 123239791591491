.imageGallery {
    width: 100%;
    max-width: 800px;
    margin: 20px 0;
  }
  
  .galleryGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
  }
  
  .galleryItem {
    width: 100%;
    height: auto;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal {
    position: relative;
    width: 80%;
    max-width: 600px;
    text-align: center;
  }
  
  .modalImage {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    font-size: 24px;
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .arrow:hover {
    color: #ddd;
  }
  .txt{
    color: #52047c;
    direction: rtl;
  }
  
  .arrow:first-of-type {
    left: 10px;
  }
  
  .arrow:last-of-type {
    right: 10px;
  }
  