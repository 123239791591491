.para {
  font-family: 'IBM Plex Sans Arabic', sans-serif;
  font-weight: 700; 
  font-size: 85px;
  color: white;
  text-align: right;
}

.container1 {
  background: linear-gradient(to left, #4f0078, #33004e);
  padding: 31px 0px 0px 0px;
  color: white;
  position: relative;
  border-bottom-left-radius: 50px;
}
.nodeco{
  text-decoration: none;
}
@keyframes rotateAround {
  0% {
    transform: rotate(0deg) translateX(1cm) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(1cm) rotate(-360deg);
  }
}

.icona {
  animation: rotateAround 5s linear infinite;
  height: 188px;
  width: 162px;
  top: 19%;
  right: 26.5%;
  position: absolute;
}
.para2 {
  
  font-weight: 100; 
  font-size: 49px;
  color: #b099bd;
  text-align: right;
  font-Family: 'IBM Plex Sans Arabic';
  font-weight:100
}

.para3 {
  font-size: 42.47px;
  color: #452780;
  text-align: center;
}

.para2-regular {
  font-family: 'IBM Plex Sans Arabic', sans-serif;
  font-weight: 400; 
  font-size: 31.09px;
}

.logo1 {
  width: 100%; 
  color: white;
}
.logoo1{
  width: 100%;
  height: auto; /* Maintain the aspect ratio */
  object-fit: cover; /* Ensure the image covers the container */
  margin: 0; /* Remove default margin */
  padding: 0; 
  margin-bottom: -28.9px;
}
.col {
  padding: 0 !important; /* Remove padding if there's any */
  margin: 0 !important; /* Remove margin if there's any */
}
.text1{
  font-Family: 'IBM Plex Sans Arabic';
  font-weight: 100;
  text-align: right !important;
  color: white;
  font-size: 21px;
  
}
.textt{
  font-Family: 'IBM Plex Sans Arabic';
  font-weight: 600;
  text-align: right !important;
  color: white;
}
.logoo{
  top: 18px;
  left: 21px;
  width: 70px;
  height: 70px;
}
.all{
  padding-top: 60px;
  padding-right: 10px;
  padding-left: 10px;
  height: 241.6px !important;
  padding-bottom: 0 !important;
  background: linear-gradient(to right, #4f0078, #33004e);
}
.container3 {
  background: linear-gradient(to right, #220034, #50007a);
  margin-top: 8%;
  position: relative; /* Ensure position is relative */
  overflow: visible; /* Ensure overflow is visible */
  padding: 50px;
  border-bottom-left-radius: 50px; 
}
.introduction{
  /* background: linear-gradient(to right, #220034, #50007a); */
  position: relative;
  border-bottom-left-radius: 50px; 
  height: 700px;
  padding: 0 50px;
  overflow: hidden;
}
.introduction::before{
  content: "";
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(to right, #220034, #50007a);
  z-index: 1;
}
.container4{
  background: linear-gradient(to bottom, #4d0075, #380055);
  box-sizing: border-box;
  padding: 124px;
  border-bottom-left-radius: 50px;
}
.customimage {
  position: absolute;
  top: -115px !important; 
  left: -45px;
  width: 47.8% !important;
}
.imgFather{
  position: relative;
}
.customImg {
  position: absolute;
  top: 0 !important; 
  left: 0;
  height: 100%;
  z-index: 2;
  /* width: 47.8% !important; */
}
.words{
  position: relative;
  z-index: 2;
  padding-bottom: 19% !important;
}
.para4 {
  font-size: 50px;
  font-weight: 700; 
  font-family: 'IBM Plex Sans Arabic', sans-serif;
  color: white;
  text-align: right;
  line-height: 100px;
}
.ton{
  justify-content: end;
}
.para5 {
  font-size: 31px;
  font-weight: 100;
  color: white;
  text-align: right;
  font-family: 'IBM Plex Sans Arabic', sans-serif;
}
.bttn{
  color: #773997 !important;
  background-color: white !important;
}
.bttn:hover{
  color: white !important;
  background-color:#773997 !important;
}
.fixedPriceContainer{
  height: 80px !important; 
}
.logo6{
  height: 65px;
  width: 55px;
  border-radius: 8px;
}
.bgl{
  background: linear-gradient(to right,  #450069,#530180);
  
}
.bgl1{
  background-color: #2e004c !important;

}
.txt{
  font-family: 'IBM Plex Sans Arabic';
  text-align: right;
}
.txt img {
  width: 155px;
  transform: translateX(10px);
}
.txt1{
  color: white;
  font-weight: 600 !important; /* Semi-bold */
  font-size: 20.48px !important;
}
.txt2{
  color: #e9e0ee;
  font-weight: 200 !important; /* Semi-bold */
  font-size: 17.48px !important;
}
.round {
  border-radius: 30px !important; /* Adjust the value for the desired roundness */
  overflow: hidden !important;   /* Ensures content does not overflow the rounded corners */
}
.txt5{
  font-weight: bold !important; /* Semi-bold */
  font-size: 65.21px !important;
  color: white;
}
.txt6 {
  font-size: 34.4px; 
  font-weight: lighter;
  color: white;
  text-align: center;
}

.txt7 {
  color: #2e004c;
  text-align: center;
  font-size: 70px;
  font-weight: 700;
  font-family: 'IBM Plex Sans Arabic';
  margin: 0;
  line-height: 01;
  margin-top: 1rem !important;
}

.txt8 {
  color: #63427a;
  text-align: center;
  font-size: 24px; 
  font-weight: 400;
}
.container5{
  
  position: relative;
}
.container5::before{
  content: "";
  position: absolute;
 
  top: 100%;
  right: 0;
  left: 0;
  height: 200px;
  z-index: -1;
}
.txt9{
  color: #4c3388;
  font-size: 50px;
  font-family: 'IBM Plex Sans Arabic';
  font-weight: bold !important;
  text-align: center;
}
.timer {
  color: red; /* تغيير اللون إلى الأحمر */
  font-size: 25px; /* حجم النص */
  font-weight: bold; /* جعل النص عريضًا */
  text-align: center; /* محاذاة النص في المنتصف */
  width: 100%; /* جعل التايمر يأخذ عرض الحاوية */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* ملء ارتفاع الحاوية */
}

.whatsappbutton {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #431960;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 30px;
  text-decoration: none;
}

.whatsappicon {
  font-size: 35px; 
  color: white !important; 
}

.txt10 {
  color: #52047c;
  font-size: 31px;
  text-align: center;
  font-family: 'IBM Plex Sans Arabic';
  font-weight: 400 !important; /* Set a lighter font weight */
}
.icons{
  width: 27px;
}


@media (min-width: 1700px) and (max-width: 1920px) {
  .words {
    padding-bottom: 13% !important;
  }
}

@media (max-width:1400px){
  .level{
    font-size: 16px !important;
  }
  .para{
    font-size: 70px;
  }
  .para2{
    font-size: 39px !important;
  }
  .logoo1{
    margin-bottom: -24.9px !important;
  }
  .words{
    padding-bottom: 29% !important;
  }
  .para4{
    font-size: 36px;
    line-height: 78px;
  }
  .para5{
    font-size: 23px;
  }
  .text1{
    font-size: 18px;
  }
}
@media (max-width:1237px){
  .customimage {
    display: none;
  }
  .para2{
    font-size: 39px;
  }
  .logoo1{
    margin-bottom: -22.9px !important;
  }
  .text1{
    font-size: 17px;
  }
}
@media (max-width:1200px){
  .logoo1{
    margin-bottom: -20px !important;
  }
  
  .para2{
    font-size: 36px;
  }
  .textt{
    margin-top: 30px;
  }
  .introduction{
    height: 530.1px;
    padding: 0 10px;
  }
  .para4{
    font-size: 38px;
    line-height: 45px;
  }
  .para5{
    font-size: 23px;
    margin-bottom: 20px;
  }
  .container4{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .txt5{
    font-size: 45px !important;
    margin: 0;
  }
  .txt6{
    font-size: 24px;
    margin: 0;
  }
  .txt7{
    font-size: 30px;
    margin: 0;
  }
  .txt8{
    font-size: 15px;
  }
  .words{
    padding-bottom: 19% !important;
    padding-right: 10px;
  }
  .level{
    font-size: 14px !important;
  }
  .txt img{
    width: 120px;
  }
  .text1{
    font-size: 15px;
  }

}
@media (max-width:1159px){
  .para2{
    font-size: 35px !important;
  }
  .para{
    font-size: 64px;
  }
  .introduction{
    height: 530.1px;
    padding: 0 10px;
  }
  .para4{
    font-size: 33px;
    line-height: 45px;
  }
  .para5{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .words{
    padding-bottom: 23% !important;
    padding-right: 10px;
  }
}
@media (max-width:991.98px){
  .logoo1{
    margin-bottom: -16px !important;
  }
  .para{
    font-size: 50px;
  }
  
  .para2{
    font-size: 27px !important;
  }
  .textt{
    margin-top: 30px;
  }
  .introduction{
    height: 406.1px;
    padding: 0 10px;
  }
  .para4{
    font-size: 26px;
    line-height: 45px;
  }
  .para5{
    font-size: 16px;
    margin-bottom: 20px;
  }
  .container4{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .txt5{
    font-size: 45px !important;
    margin: 0;
  }
  .txt6{
    font-size: 24px;
    margin: 0;
  }
  .txt7{
    font-size: 30px;
    margin: 0;
  }
  .txt8{
    font-size: 15px;
  }
  .words{
    padding-bottom: 14% !important;
  }
  .text1{
    font-size: 14px;
  }
}

@media (max-width:888px) {
  .ton{
    justify-content: center !important;
  }
  .text1{
    font-size: 13px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .ton{
    justify-content: center !important;
  }
}

@media (max-width: 767.98px) {
  .logoo1{
    width: 80%;
    margin: auto;
    margin-bottom: -3.69% !important;
  }
  .icona{
    display: none;
  }
  .para, .para2{
    text-align: center;
  }
  .para {
    font-size: 8vw;
  }
  .para2 {
    font-size: 5vw;
  }

  .para3 {
    font-size: 5vw;
  }

  .txt1,
  .txt2 {
    font-size: 3vw;
  }

  .customimage {
    display: none;
  }
  .round{
    margin-bottom: 15px;
  }
  .introduction{
    height: 340.1px;
    padding: 0 10px;
  }
  .words{
    padding-left: 0;
    padding-bottom: 20% !important;
  }
  .para4{
    font-size: 20px;
    line-height: 26px;
  }
  .para5{
    font-size: 13px;
    margin-bottom: 10px;
  }
  .logo6{
    margin: auto;
  }
  .container4{
    padding: 20px;
    border-bottom-left-radius: 0;
  }
  .txt5{
    font-size: 36px !important;
  }
  .level{
    font-size: 16px !important;
  }
  .txt img{
    width: 155px;
  }
  .logo6{
    height: 49px;
    width: 49px;
  }
}
@media (max-width: 575.98px) {
  .logoo1{
    margin-bottom: -14px;
  }
  .introduction{
    height: auto;
    border-bottom-left-radius: 0;
  }
  .introduction::before{
    top: 0;
  }
  .words{
    padding-left: 0px;
    /* padding: 20px 20px; */
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    margin-top: 40px;
    
  }
  .para4{
    font-size: 25px;
    line-height: 40px;
    text-align: center;
  }
  .para5{
    font-size: 21px;
    margin-bottom: 10px;
    text-align: center;
  }
  .bttn{
    margin: auto;
    margin-top: 10px;
  }
  .txt5{
    font-size: 24px !important;
  }
  .txt6{
    font-size: 18px;
    margin: 0;
    margin-top: 10px;
  }
  .txt7{
    font-size: 26px;
    margin: 0;
  }
  .txt8{
    font-size: 18px;
  }
  .round{
    border-radius: 20px !important;
    overflow: hidden !important;
  }
  @media(max-width:433px){
    .para4{
      font-size: 19px;
    }
    .para5{
      font-size: 15px;
    }
  }
}