.whatsapp-button {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #25d366;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 30px;
  text-decoration: none;
}

.whatsapp-icon {
  font-size: 350px; /* حجم الأيقونة */
  color: red !important; /* لون الأيقونة */
}
