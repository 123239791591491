.galleryContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .buttonContainer {
    margin-bottom: 20px;
  }
  
  .toggleButton {
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    background-color: #ddd;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    border-radius: 5px;
  }
  
  .toggleButton.active {
    background-color: #333;
    color: white;
  }
  
  .toggleButton:hover {
    background-color: #bbb;
  }
  .txt{
    color: #52047c;
  }