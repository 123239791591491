.container1 {
    width: 100%;
    background-image: url('../../Assets/images/23.svg');
    background-size: cover; 
    background-position: center center; 
    background-repeat: no-repeat; 
    height: 70vh !important;
    
}
.para{
    font-family: 'IBM Plex Sans Arabic';
    font-weight:700;
    font-size: 67.53px;
    top: 14%;
    left: 34%;
}
.para11{
    font-family: 'IBM Plex Sans Arabic';
    font-weight:700;
    font-size: 36px;
    color: #3e1759;
    direction: rtl;
    margin-bottom: 40px;
  
}
.para1{
    font-family: 'IBM Plex Sans Arabic';
    font-weight:600;
    font-size: 30px;
    color: #3e1759;
    direction: rtl;
    margin-bottom: 100px;
}
.para2{
    font-family: 'IBM Plex Sans Arabic';
    font-weight:400;
    font-size: 30.5px;
    color: #3e1759;
    direction: rtl;
}
.para12{
    font-family: 'IBM Plex Sans Arabic';
    font-weight:700;
    font-size: 35px;
    color: #3e1759;
    direction: rtl;
    
    margin-top: 130px;
    margin-bottom: 500px;
  
}
@media (max-width: 576.98px) {
    .para{
        font-size: 50px;
    }
    .para11{
        font-size: 32px;
        margin-bottom: 30px;
    }
    .para1{
        font-size: 26px;
        margin-bottom: 40px;
    }
    .para2{
        font-size: 24px;
    }
    .para12{
        font-size: 30px;
        margin-top: 100px;
        margin-bottom: 200px;
    }
}
