.contact{
    margin: 0;
    padding: 0;
    direction: rtl;
}
.contact section{
    width: 100%;
    background-color: #2e004c;
}
.contact section .word h2{
    font-family: "IBM Plex Sans Arabic";
    font-weight: 700;
    font-size: 35px;
    color: #fff;
}
.contact section .word p{
    color: #b099bd;
    text-align: right;
    font-Family: 'IBM Plex Sans Arabic';
    font-weight: 100;
    font-size: 33px;
    max-width: 650px;
}
.contact section .word h4{
    color: #fff;
    text-align: right;
    font-Family: 'IBM Plex Sans Arabic';
    font-weight: 200;
    font-size: 30px;
    max-width: 650px;
    position: relative;
    margin-right: 30px;
    padding-right: 10px;
}
.contact section .word h4::before{
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    left: 100%;
    background-color: #fff;
    border-radius: 50%;
}
.contact section .image img{
    width: 250px;
}
.contact section .Media .Media1 a{
    text-decoration: none;
    color: #fff;
    text-align: end;
    font-Family: 'IBM Plex Sans Arabic';
    font-size: 16px;
    max-width: min-content;
    width: 245px;
    display: block;
    word-wrap: break-word;
}
.contact section .Media .Media1 img{
    width: 70px;
}
@media (max-width: 575.98px){
    .profile .gallery .project{
        height: 250px;
    }
    .contact section .image{
        display: none;
    }
    .contact section .word h2{
        font-size: 25px;
    }
    .contact section .word p{
        font-size: 22px;
        padding-left: 0.75rem;
    }
    .contact section .word h4{
        font-size: 26px;
        margin-bottom: 0;
        margin-right: 20px;
    }
    .contact section .word h4.number{
        width: 134px;
    }
    .contact section .word h4::before{
        width: 20px;
        height: 20px;
        top: 7px;
    }
}