body {
  margin: 0px;
  padding: 0px;
  font-family: 'IBM Plex Sans Arabic', sans-serif;
  font-weight: 300; /* Light weight */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input.form-control{
  direction: rtl;
}
.error{
  text-align: end;
}
@media (max-width:991.98px){
  .slick-dots {
    display: none !important;
  }
}
@media (max-width:1200px){
  #root{
    overflow-x: hidden;
  }
}