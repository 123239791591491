.container1{
    z-index: 5;
    color: white;
    font-family: "IBM Plex Sans Arabic";
   padding-top: 40px;
   padding-bottom: 120px;
   padding-right: 20px;
   padding-left: 200px;
   direction: rtl;
   font-size: 29px;
   font-weight: 500;
   right: 0;
   background-color: #37004c;
   border-bottom-left-radius: 20px; 
}
.or{
    cursor: pointer;
}
.para{
    background-color: #390052 !important;
    width: 211% !important;
    cursor: pointer;
    text-decoration: none !important;
    color: white;
    font-family: "IBM Plex Sans Arabic";
    font-size: 29px;
    font-weight: 500;
}
.profile{
    margin: 0;
    padding: 0;
    position: relative;
    direction: rtl;
}
.profile::after{
    content: "";
    position: absolute;
    background-color: #2E004C;
    height: 100px;
    top: 100%;
    z-index: -1;
    right: 0;
    left: 0;
}
.profile .information, .profile .workInformation{
    width: 100%;
    background-color: #2e004c;
}
.profile .information img{
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
}
.profile .information p{
    font-family: "IBM Plex Sans Arabic";
    font-size: 20px;
    background-color: #fff;
    border-radius: 8px;
    padding: 5px;
    color: #52047c;
    word-break: break-all;
    height: 100%;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.purple{
    background-color: #52047c !important;
    color: white;
}
.profile .workInformation .image{
    height: 120px;
}
.profile .workInformation .image img{
    width: 100px;
}
.profile .workInformation h3, .profile .workInformation p{
    font-family: "IBM Plex Sans Arabic";
    font-weight: 700;
    font-size: 30px;
    color: #fff;
}
.profile .workInformation p{
    font-family: "IBM Plex Sans Arabic";
    font-size: 20px;
    font-weight: 500;
    background-color: #fff;
    border-radius: 8px;
    padding: 5px;
    color: #52047c;
    min-width: fit-content;
    max-width: 60%;
    margin: auto;
}
.profile .gallery{
    width: 100%;
    background-color: #390065;
}
.profile .gallery h2{
    font-family: "IBM Plex Sans Arabic";
    font-weight: 700;
    font-size: 30px;
    color: #fff;
}
.bgDark{
    background-color: #2E004C !important;
}
.profile .gallery .project{
    height: 340px;
    background-color: #390065;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}
.profile .gallery .project img{
    width: 110px;
    object-fit: cover;
}
.profile .gallery .project .overlay {
  background: rgba(0, 0, 0, 0.6);
  /* opacity: 0; */
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  transition: top 0.3s ease;
}
.profile .gallery .project:hover .overlay {
  top: 0;
}
.profile .gallery .project .overlay button {
  z-index: 2;
  background-color: #390065;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
}
.profile .gallery .project .overlay button:hover {
  background-color: #2E004C;
}
.video-element {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
}
.resultsModal {
    width: 75%;
    height: auto;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-top: 0;
}
.bgCover{
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.closeButton {
  align-self: flex-start;
  font-size: 50px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #333;
  margin-left: auto;
}
@media (max-width: 1200px) {
    .formField {
        flex: 1 1 48%; 
    }
    
}
@media (max-width: 1199.98px){
    .profile .gallery .project{
        height: 250px;
    }
}

@media (max-width: 992px) {
    .formField {
        flex: 1 1 100%; /* العرض 100% للشاشات المتوسطة */
    }
    .form {
        gap: 15px;
    }
    .dis{
        margin-top:40px ;
        padding-top: 40px;
    }
}

@media (max-width: 768px) {
    .input, .select, .submitButton, .submitButton1 {
        padding: 8px; 
    }
    .dis{
        margin-top:50px ;
    }
    .resultsModal {
        width: 95%;
        padding: 15px;
        padding-top: 0;
    }
}

@media (max-width: 576px) {
    .formField {
        flex: 1 1 100%; 
    }
    .form {
        gap: 10px;
    }
    .dis{
        margin-top:40px ;
    }
    .para{
        font-size: 18px;
    }
    .container1{
        padding-left: 124px;
    }
}
