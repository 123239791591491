.container1 {
  background: linear-gradient(to left, #50007a, #420065);
  direction: rtl;
  padding-top: 35px;
  padding-bottom: 20px;
  padding-right: 40px;
  padding-left: 40px;
  border-bottom-left-radius: 50px; 
}
.par{
  color: white;
  font-family: 'IBM Plex Sans Arabic';
  font-weight: 100;
  font-size: 29.83px;
}
/* Who.module.css */

.successMessage {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  padding: 15px;
  border-radius: 5px;
  margin-top: 15px;
  text-align: center;
  transition: opacity 0.5s;
  opacity: 1;
}

.errorMessage {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 15px;
  border-radius: 5px;
  margin-top: 15px;
  text-align: center;
  transition: opacity 0.5s;
  opacity: 1;
}


.para{
  color: white;
  font-family: 'IBM Plex Sans Arabic';
  font-weight: 700;
  font-size: 50.45px;
  padding-bottom: 20px;
}
.para1{
  color: white;
  font-family: 'IBM Plex Sans Arabic';
  font-weight: 100;
  font-size: 29.83px;
  padding-bottom: 20px;
}
.par3{
  margin-bottom: 120px;}
.location{
  font-family: 'IBM Plex Sans Arabic';
  font-weight: 700;
  font-size: 37.49px;
  color: #3e1759;
  text-align: right;
}
.location1{
  font-family: 'IBM Plex Sans Arabic';
  font-weight: 100;
  font-size: 15.49px;
  color: #917aa0;
  text-align: right;

}
.container3 {
  background: linear-gradient(to right, #420065, #50007a);
  padding: 20px;
  border-radius: 20px;
  max-width: 65%;
  margin: auto;
  color: white;
  margin-top: 200px;
  margin-bottom: 200px;
}

.formTitle {
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  font-family: 'IBM Plex Sans Arabic';
}

.formGroup {
  margin-bottom: 20px;
}

.formControl {
  border: 1px solid white;
  border-radius: 10px;
  background-color: transparent;
  color: white;
  width: 100%;
  direction: rtl;
  height:60px;
  padding-right: 10px;
}

.formControl::placeholder {
  color: #ddd;
}

.btnSubmit {
  background-color: white !important;
  color: black !important;
  font-family:'IBM Plex Sans Arabic';
  font-size: 25.32px;
  font-weight: 600;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
}
@media (max-width: 767px) {
  .kn, .kn2{
    padding-right: 2rem !important;
  }
  .container3{
    max-width: 100%;
  }
}
@media (max-width: 576.98px) {
  .container1{
    padding-right: 30px;
    padding-left: 30px;
  }
  .para{
    font-size: 40px;
    padding-bottom: 10px;
  }
  .para1{
    font-size: 24px;
    padding-bottom: 10px;
  }
  .par{
    font-size: 24px;
  }
  .par3{
    margin-bottom: 100px;
  }
}
