.videoGallery {
  width: 100%;
  max-width: 800px;
  margin: 20px 0;
}

.galleryGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

/* .videoContainer {
  position: relative;
} */

.galleryItem {
  width: 100%;
  height: 200px; 
  border-radius: 8px;
  object-fit: cover;
  transition: transform 0.3s ease; 
}

.videoContainer:hover .fullscreenOverlay {
  height:90%; 
  opacity: 1;
}

.videoContainer:hover .galleryItem {
  transform: scale(1.05); 
}

.txt {
  color: #52047c;
  direction: rtl;
}
.pos{
  position: relative;
}

.fullscreenOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease; 
}

.fullscreenIcon {
  color: white;
  font-size: 30px;
}
