.container1{
    background: linear-gradient(to left, #50007a, #2b0042);
    border-top-right-radius: 70px;
    border-top-left-radius: 70px;
}
.trans{
    background-color: transparent !important;
    height: 50px !important;
    color: white !important;
    font-Family:'IBM Plex Sans Arabic';
    margin-top:35px
}
.trans::placeholder {
    color: white !important; /* لون النص التوضيحي */
    font-size: 20px;
    font-weight: 100;
    font-Family:'IBM Plex Sans Arabic' !important;
  }

  @media (max-width: 768px) {
    .container1 {
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  
    .container1 p {
      font-size: 18px;
      font-weight: 500;
    }
  
    .trans {
      margin-top: 20px !important;
      height: 40px !important;
      font-size: 18px !important;
    }
  
    .btn {
      font-size: 18px !important;
      height: 40px !important;
    }
  
    .row > div {
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 576px) {
    .container1 {
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .nt{
        margin-top: 30px !important;
    }
    .container1 p {
      font-size: 18px;
      font-weight: 500;
    }
  
    .trans {
      margin-top: 20px !important;
      height: 40px !important;
      font-size: 18px !important;
    }
  
    .btn {
      font-size: 18px !important;
      height: 40px !important;
    }
  
    .row > div {
      margin-bottom: 20px;
    }
    .ft{
        justify-content: center;
    }
  }
  