.course{
    margin: 0;
    padding: 0;
    direction: rtl;
    background-color: #360060;
    min-height: 100vh;
    position: relative;
}
.course::after{
    content: "";
    position: absolute;
    background-color: #360060;
    height: 100px;
    top: 100%;
    z-index: -1;
    right: 0;
    left: 0;
}
.course .header .left h1, .course .header .right h2{
    font-family: 'IBM Plex Sans Arabic';
    font-weight:700;
    font-size: 36px;
    color: #fff;
    direction: rtl;
}
.course .header .left .specifications1 p, .course .header .left .specifications2 p{
    font-Family: 'IBM Plex Sans Arabic';
    font-weight: 100;
    font-size: 24px;
    margin: 0;
    color: #fff;
    direction: rtl;
}
.course .header .left .specifications1 img{
    width: 200px;
    border-right: 2px solid #fff;
}
.course .header .left .specifications2 img{
    width: 25px;
}
.course .header .left .specifications2 .middle{
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
}
.course .header .right .offer{
    font-weight: 300;
    position: relative;
    padding: 0 6px;
}
.course .header .right .offer::after{
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    top: 55%;
    background-color: red;
}
.course .header .right button{
    color: #773997 !important;
    background-color: white !important;
    font-size: 22px;
}
.course .header .right button:hover{
    color: white !important;
    background-color: #773997 !important;
}

/* Courses.module.css */
.course .app {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}

.course .app .container {
    margin-top: 20px;
}

.course .app .playlist{
    width: 100%;
    height: auto;
    background-color: #5600A8;
}
.course .app .playlist h3{
    font-family: 'IBM Plex Sans Arabic';
    font-weight:600;
    font-size: 18px;
    color: #fff;
    direction: rtl;
    padding: 10px 10px 0 0;
}
.course .app .playlist p{
    font-family: 'IBM Plex Sans Arabic';
    font-weight:100;
    font-size: 16px;
    color: #fff;
    direction: rtl;
    padding-right: 10px;
}
.course .app .playlist .playlistItem{
    width: 100%;
    box-sizing: border-box;
    height: 370px; 
    overflow-y: auto; 
    scroll-behavior: smooth;
    background: #3E0074;
    border-bottom: 1px solid #360060;
}
/* Custom scrollbar styles */
.course .app .playlist .playlistItem::-webkit-scrollbar {
    width: 14px;
}
.course .app .playlist .playlistItem::-webkit-scrollbar-thumb {
    background-color: #470C83;
    border: 2px solid #551198;
}
.course .app .playlist .playlistItem::-webkit-scrollbar-track {
    background-color: #551198;
}
.course .app .playlist .playlistItem::-webkit-scrollbar-thumb:hover {
    background-color: #4E1487;
}
.course .app .playlist .playlistItem::-webkit-scrollbar-button:single-button {
    background-color: #551198;
    display: block;
    border-style: solid;
    height: 13px;
    width: 13px;
    background-size: 12px;
}
.course .app .playlist .playlistItem::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #470C83 transparent;
}
.course .app .playlist .playlistItem::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: 6px 6px 0 6px;
    border-color: #470C83 transparent transparent transparent;
}

.course .app .playlist .playlistItem .item{
    width: 100%;
    background-color: #3E0074;
    padding: 10px;
    padding-right: 0;
    cursor: pointer;
    transition: 0.5s;
    border-bottom: 1px solid #5600A8;
}
.course .app .playlist .playlistItem .item.active, .course .app .playlist .playlistItem .item:hover{
    background-color: #4E1487;
}
.course .app .playlist .playlistItem .item img{
    width: 50px;
}
.course .app .playlist .playlistItem .item h4{
    font-family: 'IBM Plex Sans Arabic';
    font-weight:600;
    font-size: 18px;
    color: #fff;
    direction: rtl;
    margin: 0;
}
.course .app .playlist .playlistItem .item h5{
    font-family: 'IBM Plex Sans Arabic';
    font-weight:bold;
    font-size: 20px;
    color: #A5A5A5;
    direction: rtl;
    margin: 0;
}

.videoPlayer {
    height: 90% !important;
}

.course .information .right, .course .information .left{
    width: 100%;
    padding-top: 10px;
    background-color: #5600A8;
}
.course .information .right h2, .course .information .left .header h2{
    border-right: 1px solid #fff;
    padding-right: 10px;
    margin: 10px;
    margin-bottom: 15px;
    background-color: #5600A8;
    font-family: 'IBM Plex Sans Arabic';
    font-weight:600;
    font-size: 18px;
    color: #fff;
    direction: rtl;
}
.course .information .right h3, .course .information .right p{
    font-family: 'IBM Plex Sans Arabic';
    font-weight:100;
    font-size: 14px;
    color: #fff;
    direction: rtl;
    background-color: #3E0074;
    margin: 0;
    padding: 10px;
}
.course .information .right h3{
    font-size: 17px !important;
}
.course .information .right .programs{
    width: 100%;
    background-color: #3E0074;
    padding: 10px;
}
.course .information .right .programs img{
    width: 60px;
}
.course .information .right .programs p{
    padding: 0;
}
.course .information .left .body{
    background-color: #3E0074;
    padding: 10px;
    font-family: 'IBM Plex Sans Arabic';
}
.course .information .left .body h3{
    padding-right: 10px;
    font-family: 'IBM Plex Sans Arabic';
    font-weight:600;
    font-size: 18px;
    color: #fff;
    direction: rtl;
}
.course .information .left .body .requirements p, .course .information .left .body .language p{
    padding: 0 10px 0 0;
    margin: 0;
}
.course .information .left .body p.para2{
    padding-bottom: 0;
}
.course .information .left .body i{
    font-size: 16px !important;
    margin: 0 !important;
    padding-left: 5px;
}
.thumbnailContainer {
  margin: 20px 0;
}

.thumbnail {
  width: 80%; 
  height: auto;
  border-radius: 8px;
}
.description ul {
  position: unset !important;
  margin: 0;
  border-radius: 10px;
  text-align: right;
  background-color: unset !important;
}

.course .information .left .body .requirements .device{
    width: 250px;
}
.course .information .left .body .requirements .device img{
    width: 100%;
}
.toggleIcon {
    font-size: 1.5rem;
    color: white; 
    padding-left: 8px;
  }
  
.course .information .left .body a{
    padding: 0;
    padding-right: 10px;
    text-decoration: none;
    color: #6B00D2;
}
.course .information .left .body .Certificates p{
    padding-bottom: 0;
    padding-top: 0;
}
.videoOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    pointer-events: none;
  }
  


.modalOverlay {
    position: fixed;
    z-index: 100000000000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
  .modalContent {
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 25%;
    max-width: 400px;
    position: relative;
  }
  .previousButton {
    padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px; 
}

  .closeButton {
    position: absolute;
    top: 5px;
    right: 10px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 5px;
  }
  
  .formGroup input, .formGroup textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .btt{
    background-color: #773997 !important;
    color: white;
    border-radius: 10px;
    margin-left: 10px;
  }
  .btt1{
    color: white;
    border-radius: 5px !important;
    margin-left: 10px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    border: none;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    pointer-events: none;
  }
  
  
  button[type="submit"] {
    padding: 10px 20px;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  .attachmentItem {
    list-style-type: none;
    text-align: center;
    width: auto;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.attachmentsModal {
  background: #fff;
  width: 75%;
  max-width: 800px;
  padding: 20px;
  border-radius: 8px;
  position: relative;
}

.attachmentsContent {
  max-height: 60vh;
  overflow-y: auto;
  margin-top: 20px;
}

.attachmentLink {
    text-decoration: none;
    color: inherit;
}
.purple{
  background-color:#551198;
  color: white;
  border-radius: 5px !important;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
}
.attachmentContent {
    display: flex;
    align-items: center;
}

.attachmentIcon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.attachmentTitle {
    font-size: 0.8rem;
    text-align: left;
}

.attachments {
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.messi{
  margin-top: 20px;
  margin-right: 20px;
}
.videoTitle {
  font-size: 24px; 
  font-weight: bold; 
  color: #333; 
  margin: 20px 0; 
  text-transform: capitalize;
  font-family: 'Arial', sans-serif; 
  color: white;
}

/* لتغطي المودال كامل الشاشة مع تأثير الحجب */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

/* المحتوى داخل المودال */
.modalContent {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  transform: scale(0.9);
  animation: slideIn 0.5s ease-out forwards;
}

/* الأنيميشن الخاصة بالدخول */
@keyframes slideIn {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

/* الأنيميشن الخاصة بالظهور */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* الخيارات الخاصة بطريقة الدفع */
.paymentOptions {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.paymentButton {
  background-color: #470C83;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}
.paymentButton1 {
  background-color: #153c63;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}
.paymentOptions {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.paymentOptions label {
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.paymentOptions input {
  margin-right: 10px;
}


.paymentButton:hover {
  background-color: #5f437a;
}
.paymentButton1:hover {
  background-color: #3c5f83;
}

/* تمييز الدفع المحدد */
.selected {
  background-color: #28a745;
}

/* زر التالي */
.nextButton {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
}

.nextButton:hover {
  background-color: #218838;
}







@media (max-width: 1200px) {
  .modalContent {
    width: 30%;
    max-width: 500px;
    padding: 15px;
  }
  
}

@media (max-width: 992px) {
  .modalContent {
    width: 50%;
    max-width: 400px;
    padding: 15px;
  }
  .thumb{
    display: none !important;
  }
  .ig{
    width: 30% !important;
  }
  .videoPlayer {
    height: 83% !important;
}
}

@media (max-width: 768px) {
  .modalContent {
    width: 70%;
    max-width: 300px;
    padding: 10px;
  }
  .videoPlayer {
    height: auto;
}
  .ig{
    width: 30% !important;
  }
  .attachments {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
    margin-top: 10px;
}

.attachmentItem {
    margin-bottom: 10px;
}
}

@media (max-width: 576px) {
  .modalContent {
    width: 90%;
    max-width: 280px;
    padding: 10px;
  }
  .videoPlayer {
    height: auto;
}
  .ig{
    width: 30% !important;
  }

  .modalContent h2 {
    font-size: 18px;
  }

  .btt, .btt1 {
    font-size: 16px;
    padding: 8px;
  }

  
  button[type="submit"] {
    font-size: 16px;
    padding: 8px;
  }
  .course .header .left .specifications1 p, .course .header .left .specifications2 p{
    font-size: 19px;
  }
  .videoPlayer {
    height: 75% !important;
}
}

@media (max-width: 375px) {
  .modalContent {
    width: 95%;
    max-width: 260px;
    padding: 8px;
  }
  .ig{
    width: 30% !important;
  }
  .videoPlayer {
    height: auto;
}
  .modalContent h2 {
    font-size: 16px;
  }

  .btt, .btt1 {
    font-size: 14px;
    padding: 6px;
  }

  button[type="submit"] {
    font-size: 14px;
    padding: 6px;
  }
}

@media (max-width: 768px) {
    .course .header {
        padding: 10px;
    }
    .course .header .left h1,
    .course .header .right h2 {
        font-size: 20px; 
    }
    .ig{
        width: 30% !important;
      }
    .course .header .left .specifications1 img,
    .course .header .left .specifications2 img {
        width: 24px; /* تصغير حجم الصور */
    }
    .course .header .right button {
        font-size: 16px; /* تصغير حجم الأزرار */
    }
}

/* تعيين تصميم الـ header في الشاشات الأصغر من 576 بكسل */
@media (max-width: 576.98px) {
    .course .header .left h1,
    .course .header .right h2 {
        font-size: 18px;
    }
    .course .header .left .specifications1 img,
    .course .header .left .specifications2 img {
        width: 30px;
    }
    .course .header .right button {
        font-size: 14px;
    }
    .videoPlayer {
        height: auto;
    }
    .ig{
        width: 30% !important;
      }
}
@media (max-width: 411px) {
    /* .course .header .left h1,
    .course .header .right h2 {
        font-size: 14px;
    }
    .course .header .left .specifications1 img,
    .course .header .left .specifications2 img {
        width: 14px;
    } */
    .course .header .right button {
        font-size: 14px; 
    }
    .videoPlayer {
        height: auto;
    }
    .ig{
        width: 30% !important;
      }
    /* .course .header .left .specifications1 p, .course .header .left .specifications2 p{
        font-size: 11px;
    } */
}