.container1 {
    width: 100%;
    background-image: url('../../Assets/images/23.svg');
    background-size: cover; 
    background-position: center center; 
    background-repeat: no-repeat; 
    height: 65vh !important;
    
}
.para{
    font-family: 'IBM Plex Sans Arabic';
    font-weight: 700;
    font-size: 48px;
    top: 14%;
    width: 100%;
}
.pur{
    background-color: #3e1759 !important;
    color: white !important;
}
.pur:hover{
    color: black !important;
    background-color: #3e1759 !important;
}
.para1{
    font-family: 'IBM Plex Sans Arabic';
    font-weight:700;
    font-size: 35px;
    color: #3e1759;
    direction: rtl; 
}
.para2{
    font-family: 'IBM Plex Sans Arabic';
    font-weight:400;
    font-size: 29px;
    color: #3e1759;
    direction: rtl;
}
.para3{
    font-family: 'IBM Plex Sans Arabic', sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #3e1759;
    direction: rtl;
}
.formm{
    height: 50px !important;
    border-radius: 15px !important;
    width: 100% !important;
    border: 1.5px #3e1759 solid !important;
}
@media (max-width: 576.98px) {
    .para2{
        font-size: 24px;
    }
}